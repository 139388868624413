import type { ModuleConfig } from '../types';
import { RemoteWorkspaceOnline, loadRemoteWorkspaceOnline } from './remote';

const CONFIG: ModuleConfig = {
  key            : 'WSO',
  title          : 'wso',
  registeredTitle: 'wsoRegistered',
  featureId      : 'f1be4fe26e9a4ac99ec48767173aecd1',
  icon           : 'MinitabWorkspaceIcon',
  card           : {
    title         : 'wsoRegistered',
    description   : 'wsoDescription',
    tagline       : 'qualityProject',
    icon          : 'MinitabWorkspaceIcon',
    heroIcon      : 'WorkspaceHeroIcon',
    fillColor     : '#FFF399',
    hoverFillColor: '#FFE200',
  },
  preloadRemoteModule: loadRemoteWorkspaceOnline,
  remoteModule       : RemoteWorkspaceOnline,
  storage            : {
    defaultProjectName: 'wsoDefaultProject',
    defaultExtension  : '.wspx',
    defaultPlaneState : {},
    filter            : ['.wspx', '.qcpx'],
    passthroughFilter : [],
  },
};

export default CONFIG;
