import type { CreatePlaneArgs } from './types';
import type { Plane, WritablePlaneState } from '../types';
import type { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import Logger from '../../../../../clients/logger';
import { PLANE_HEALTH_TYPES, PLANE_STATUS_TYPES } from '../constants';
import { createPlaneSchema } from './schema';

export const createPlane = {
  reducer: (
    state: WritablePlaneState,
    action: PayloadAction<Plane>,
  ) => {
    state[action.payload.id] = action.payload;
  },
  prepare: (payload: CreatePlaneArgs) => {
    const id = payload?.id || uuid();
    const createdDate = new Date().toISOString();
    const plane = {
      id,
      createdDate,
      lastUpdatedDate: createdDate,
      module         : payload.module,
      name           : payload.name,
      extension      : payload.extension,
      icon           : payload.icon,
      cloudStorageId : payload.cloudStorageId,
      state          : { ...(payload?.state || {}) },
      // By default created planes have a pending status. Somewhere later
      // once all the prep is done for the plane it will be updated to "loading"
      // to signify the remote module can start loading.
      status         : PLANE_STATUS_TYPES.PENDING,
      health         : PLANE_HEALTH_TYPES.HEALTHY,
      dirty          : payload.dirty ?? false,
    };

    const { valid, errors } = createPlaneSchema.validate(plane);
    if (!valid) {
      Logger.fatal(errors.map(e => e.message).join(', '));
    }

    return { payload: plane as Plane };
  },
};
