import { createElement } from 'react';
import CenteredCircularProgress from '../../core/utils/centered-circular-progress';
import withErrorBoundary from '../../core/utils/error-boundary';
import lazy from '../../core/utils/lazy';
import createIntegratedRemoteModuleLoader from '../../core/utils/load-remote-module';
import remoteModuleUrlConfigs from '../../core/utils/remote-url-configs';
import withSuspense from '../../core/utils/with-suspense';

const loadRemoteReportingTool = createIntegratedRemoteModuleLoader(
  'reportingtool',
  'REPORTING_TOOL',
  remoteModuleUrlConfigs.REPORTING_TOOL,
);

const LazyRemoteReportingTool = lazy(loadRemoteReportingTool);

const RemoteReportingTool = withErrorBoundary(
  withSuspense(LazyRemoteReportingTool, () => createElement(CenteredCircularProgress)),
  'RemoteReportingTool',
);

export { loadRemoteReportingTool, RemoteReportingTool };
