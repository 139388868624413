import type { ModuleConfig } from '../types';
import { RemoteBrainstorm, loadRemoteBrainstorm } from './remote';

const CONFIG: ModuleConfig = {
  key      : 'BRAINSTORM',
  title    : 'brainstorm',
  icon     : 'BrainstormIcon',
  // Use `MSSO` productId since these are included if you have a subscription to Minitab Online.
  productId: 'e68147de1c46451bafadcfcc44e196cc',
  card     : {
    title         : 'brainstorm',
    description   : 'brainstormDescription',
    tagline       : 'ideas',
    icon          : 'BrainstormIcon',
    heroIcon      : 'BrainstormHeroIcon',
    fillColor     : '#BFB9DA',
    hoverFillColor: '#5E4FA2',
  },
  preloadRemoteModule: loadRemoteBrainstorm,
  remoteModule       : RemoteBrainstorm,
  storage            : {
    // The default extension is used when the user creates a new plane.
    defaultExtension : '.mbpx',
    // The default plane props are used when the user creates a new plane.
    defaultPlaneState: {},
    // The file extensions that are allowed to be uploaded.
    filter           : ['.mbpx'],
    // The file extensions that allow the remote modules to handle opening operations in a custom manner.
    passthroughFilter: [],
  },
};

export default CONFIG;
