import type { PlaneState } from '../types';
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { deepClone } from '@mtb/utilities';
import { PLANE_HEALTH_TYPES, PLANE_STATUS_TYPES } from '../constants';
import { PlaneError } from '../error';
import { initializePlane } from './thunk';

export const initializePlaneReducer = (builder: ActionReducerMapBuilder<PlaneState>) => {
  return builder
    .addCase(initializePlane.pending, (state, action) => {
      const id = action.meta.arg;
      if (id && state[id]) {
        state[id].health = PLANE_HEALTH_TYPES.UNKNOWN;
        state[id].status = PLANE_STATUS_TYPES.PENDING;
        state[id].requestId = action.meta.requestId;
      }
    })
    .addCase(initializePlane.fulfilled, (state, action) => {
      const id = action.meta.arg;
      if (id && state[id]) {
        state[id].status = PLANE_STATUS_TYPES.IDLE;
        delete state[id].requestId;
        deepClone(state[id]);
      }
    })
    .addCase(initializePlane.rejected, (state, action) => {
      const id = action.meta.arg;
      if (id && state[id]) {
        state[id] = {
          ...state[id],
          status: PLANE_STATUS_TYPES.ERROR,
          error : PlaneError.FromReduxSerialized(action.error),
        };
        delete state[id].requestId;
        action.payload = deepClone(state[id]);
      }
    });
};
