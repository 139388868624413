import type { OnOpenReason } from './constants';
import type { CloudStorageCategoryKey, StorageProviderItem, StorageProviderKey } from '@';
import type React from 'react';
import { createContext } from 'react';

export type CloudExplorerContextType = {
  id: string;
  category: CloudStorageCategoryKey;
  setCategory: React.Dispatch<string>;
  provider: StorageProviderKey;
  setProvider: React.Dispatch<string>;
  onOpen: (item: StorageProviderItem, reason: OnOpenReason) => Promise<void>;
  onError: (error?: unknown) => void;
  defaultFilter: string[];
  renderOpenInMenuItems: JSX.Element[];
  disableCache: boolean;
};

export const initialState: CloudExplorerContextType = {
  id                   : '',
  category             : '' as CloudStorageCategoryKey,
  setCategory          : () => undefined,
  provider             : '' as StorageProviderKey,
  setProvider          : () => undefined,
  onOpen               : () => Promise.resolve(),
  onError              : () => undefined,
  renderOpenInMenuItems: [],
  defaultFilter        : [],
  disableCache         : false,
};

export const CloudExplorerContext = createContext(initialState);
