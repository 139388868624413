import type { ModuleConfig } from '../types';
import { RemoteReportingTool, loadRemoteReportingTool } from './remote';

const CONFIG: ModuleConfig = {
  key      : 'REPORTING_TOOL',
  title    : 'minitabDashboards',
  icon     : 'MinitabConnectIcon',
  featureId: '7a218321eda941a4bc9590c088746be0',
  card     : {
    title         : 'minitabDashboards',
    description   : 'minitabDashboardsDescription',
    tagline       : 'exploreAndInteract',
    icon          : 'MinitabConnectIcon',
    heroIcon      : 'ReportingToolHeroIcon',
    fillColor     : '#D7E9F0',
    hoverFillColor: '#BDDBE6',
  },
  preloadRemoteModule: loadRemoteReportingTool,
  remoteModule       : RemoteReportingTool,
  storage            : {
    defaultProjectName: 'minitabDashboardsDefaultProject',
    defaultExtension  : '.mdbx',
    defaultPlaneState : { isDirty: false },
    filter            : ['.mdbx'],
    passthroughFilter : ['.mtw', '.mwx'],
  },
};

export default CONFIG;
