import type { OnOpenReason } from './constants';
import type { CloudStorageCategoryKey, StorageProviderItem, StorageProviderKey } from '@';
import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';
import { Stack } from '@mtb/ui';
import { CLOUD_STORAGE_CATEGORIES, STORAGE_PROVIDER_KEYS } from '../../constants';
import { useSessionStorage } from '../../hooks';
import { CloudExplorerContext } from './context';

type CloudExplorerProps = PropsWithChildren<{
  id: string;
  defaultFilter: string[];
  onOpen: (item: StorageProviderItem, reason: OnOpenReason) => Promise<void>;
  onError: () => void;
  renderOpenInMenuItems: JSX.Element[];
  defaultCategory?: CloudStorageCategoryKey;
  defaultProvider?: StorageProviderKey;
  disableCache?: boolean;
  sx?: object;
}>;

export const CloudExplorer = ({
  id,
  defaultFilter,
  onOpen,
  onError,
  renderOpenInMenuItems,
  defaultProvider = STORAGE_PROVIDER_KEYS.ONE_DRIVE,
  defaultCategory = CLOUD_STORAGE_CATEGORIES.NONE,
  disableCache = false,
  sx,
  children,
}: CloudExplorerProps) => {
  const [provider, setProvider] = useSessionStorage({
    noun        : 'provider',
    id,
    initialValue: defaultProvider,
    disableCache,
  });
  const [category, setCategory] = useSessionStorage({
    noun        : 'category',
    id,
    initialValue: defaultCategory,
    disableCache,
  });

  // TODO: move this to the table context
  /**
   * Opens the given storage item.
   * If the item is a folder, it will be set as the current folder.
   */
  // const handleOpenItem = useCallback(
  //   async (item: StorageProviderItem, reason: OnOpenReason) => {
  //     if (item.folder) {
  //       return setFolder(item);
  //     }

  //     try {
  //       if (!projectService.verifyBeforeOpen(item)) {
  //         return false;
  //       }
  //       await onOpen?.(item, reason);
  //     } catch (error) {
  //       console.error(error);
  //       onError?.(error);
  //     }
  //   },
  //   [cloudStorage, onError, onOpen, setFolder],
  // );

  // useDidMount(() => {
  //   const providers = ProviderStore.getProviders();
  //   if (Object.keys(providers).length === 0) {
  //     return;
  //   }

  //   const defaultProvider = ProviderStore.getDefaultProvider();
  //   if (defaultProvider) {
  //     setProvider(defaultProvider);
  //     setCategory(CLOUD_STORAGE_CATEGORIES.ALL);
  //     return;
  //   }

  //   if (providers[STORAGE_PROVIDER_KEYS.ONE_DRIVE]) {
  //     setProvider(STORAGE_PROVIDER_KEYS.ONE_DRIVE);
  //     setCategory(CLOUD_STORAGE_CATEGORIES.ALL);
  //     return;
  //   }

  //   if (providers[STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE]) {
  //     setProvider(STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE);
  //     setCategory(CLOUD_STORAGE_CATEGORIES.ALL);

  //   }
  // });

  const contextValue = useMemo(
    () => ({
      id,
      onOpen,
      onError,
      category,
      setCategory,
      provider,
      setProvider,
      defaultFilter,
      renderOpenInMenuItems,
      disableCache,
    }),
    [
      id,
      onOpen,
      onError,
      category,
      setCategory,
      provider,
      setProvider,
      defaultFilter,
      renderOpenInMenuItems,
      disableCache,
    ],
  );

  return (
    <CloudExplorerContext.Provider value={contextValue}>
      <Stack
        direction="row"
        gap={3}
        sx={{
          flex     : '1 0 auto',
          height   : '100%',
          width    : '100%',
          overflowX: 'auto',
          overflowY: 'hidden',
          ...sx,
        }}>
        {children}
      </Stack>
    </CloudExplorerContext.Provider>
  );
};
