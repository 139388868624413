import type {
  InitializeDialogsOptions,
  ShowConfirmCloseDialogHandler,
  ShowOpenInDialogHandler,
  ShowPlanesDialogHandler,
  ShowStorageExplorerDialogHandler,
} from './types';
import ModuleClient from '../module';

/**
 * DialogsClient serves as a client to display dialogs in Platform.
 */
class DialogsClient {
  #showMaxPlanesDialog: ShowPlanesDialogHandler = () => Promise.resolve(true);
  #showConfirmCloseDialog: ShowConfirmCloseDialogHandler = () => Promise.resolve(true);
  #showOpenInDialog: ShowOpenInDialogHandler = () => Promise.resolve({ moduleKey: ModuleClient.ROOT.key });
  #showStorageExplorerDialog: ShowStorageExplorerDialogHandler = async () => Promise.resolve(undefined);

  get showMaxPlanesDialog() {
    return this.#showMaxPlanesDialog;
  }

  get showConfirmCloseDialog() {
    return this.#showConfirmCloseDialog;
  }

  get showOpenInDialog() {
    return this.#showOpenInDialog;
  }

  get showStorageExplorerDialog() {
    return this.#showStorageExplorerDialog;
  }

  initialize(handlers: InitializeDialogsOptions) {
    this.#showMaxPlanesDialog = handlers?.showMaxPlanesDialog;
    this.#showConfirmCloseDialog = handlers?.showConfirmCloseDialog;
    this.#showOpenInDialog = handlers?.showOpenInDialog;
    this.#showStorageExplorerDialog = handlers?.showStorageExplorerDialog;
  }
}

const dialogsClient = new DialogsClient();
export default dialogsClient;
