import type { AsyncPlaneThunkState, PlaneId } from '../types';
import CloudStorageClient from '../../../../../clients/cloud-storage';
import ModuleClient from '../../../../../clients/module';
import { createAsyncThunk } from '../../../redux-helpers/create-async-thunk';

export const closePlane = createAsyncThunk.withTypes<AsyncPlaneThunkState>()(
  'planes/closePlane',
  async (planeId: PlaneId, { getState }) => {
    const storePlane = getState().planes[planeId];
    if (!storePlane) {
      return;
    }

    await ModuleClient.Events.close(storePlane);
    await CloudStorageClient.closeProject(storePlane.cloudStorageId);
  },
);
