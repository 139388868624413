import type { CloudStorageProject } from './types';
import type { StoreProvider } from '../../store/providers/types';
import type { StoreProject } from 'lib/store/projects/types';
import ProviderStore from '../../store/providers';

export function toExternalProject(project: StoreProject): CloudStorageProject {
  return {
    ...project,
    type          : project.connection.type,
    autoSaveStatus: project.connection.autoSaveStatus,
    cloudStatus   : project.connection.cloudStatus,
    operation     : project.operation ?? null,
    breadcrumbs   : [],
  };
}

export function getDefaultSaveProvider(): StoreProvider | null {
  const defaultProvider = ProviderStore.getDefaultProvider();
  return defaultProvider ? ProviderStore.getProvider(defaultProvider) : null;
}

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};
