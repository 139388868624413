import { useMemo } from 'react';
import { Stack, Typography } from '@mtb/ui';
import {
  CLOUD_STORAGE_CATEGORIES,
  STORAGE_PROVIDER_KEYS,
} from '../../../constants';
import { useProvider } from '../../../hooks';
import { useTranslation } from '../../../services/i18n';
import { useCloudExplorer } from '../hooks';
import { ProviderAccountSettings } from './ProviderAccountSettings';
import { ProviderSaveSettings } from './ProviderSaveSettings';

type PROVIDER_TITLES_TYPE = {
  [provider: string]: string;
};
const PROVIDER_TITLES: PROVIDER_TITLES_TYPE = {
  [STORAGE_PROVIDER_KEYS.ONE_DRIVE]   : 'connection.oneDriveSetup',
  [STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE]: 'connection.googleDriveSetup',
};

type CloudExplorerProviderSettingsProps = { disabled?: boolean };
export const CloudExplorerProviderSettings = ({ disabled = false }: CloudExplorerProviderSettingsProps) => {
  const [t] = useTranslation();
  const { category, provider } = useCloudExplorer();
  const { isSignedIn } = useProvider(provider);
  const showSettings = useMemo(
    () => category === CLOUD_STORAGE_CATEGORIES.NONE,
    [category],
  );

  const providerTitle = useMemo(
    () => t(PROVIDER_TITLES[provider]),
    [t, provider],
  );

  if (!showSettings) {
    return null;
  }

  return (
    <Stack mt={2}>
      <Typography
        gutterBottom
        variant="title-xl"
        weight="semibold">
        {providerTitle}
      </Typography>
      <Stack
        gap={2}
        pl={3}
        pt={3}>
        <ProviderAccountSettings disabled={disabled} />
        {isSignedIn && <ProviderSaveSettings disabled={disabled} />}
      </Stack>
    </Stack>
  );
};
