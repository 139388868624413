import type { ModuleConfig } from '../../types';
import { MODULES } from '../../modules';

type ExtensionModuleMap = {
  [key: string]: {
    defaultModule: ModuleConfig;
    modules: ModuleConfig[];
  };
};

/**
 * Map of file extensions and the respective module interactions
 * for creating new projects or providing open in options.
 */
export const EXTENSION_MODULE_MAP: ExtensionModuleMap = {
  '.txt': {
    defaultModule: MODULES.DATACENTER,
    modules      : [MODULES.DATACENTER, MODULES.MSSO],
  },
  '.csv': {
    defaultModule: MODULES.DATACENTER,
    modules      : [MODULES.DATACENTER, MODULES.MSSO],
  },
  '.dat': {
    defaultModule: MODULES.DATACENTER,
    modules      : [MODULES.DATACENTER, MODULES.MSSO],
  },
  '.xlsx': {
    defaultModule: MODULES.DATACENTER,
    modules      : [MODULES.DATACENTER, MODULES.MSSO],
  },
  '.mtw': {
    defaultModule: MODULES.MSSO,
    modules      : [MODULES.MSSO, MODULES.DATACENTER, MODULES.REPORTING_TOOL],
  },
  '.mpj': {
    defaultModule: MODULES.MSSO,
    modules      : [MODULES.MSSO],
  },
  '.mwx': {
    defaultModule: MODULES.MSSO,
    modules      : [MODULES.MSSO, MODULES.DATACENTER, MODULES.REPORTING_TOOL],
  },
  '.mpx': {
    defaultModule: MODULES.MSSO,
    modules      : [MODULES.MSSO],
  },
  '.mac': {
    defaultModule: MODULES.MSSO,
    modules      : [MODULES.MSSO],
  },
  '.mtb': {
    defaultModule: MODULES.MSSO,
    modules      : [MODULES.MSSO],
  },
  '.mbpx': {
    defaultModule: MODULES.BRAINSTORM,
    modules      : [MODULES.BRAINSTORM],
  },
  '.qcpx': {
    defaultModule: MODULES.WSO,
    modules      : [MODULES.WSO],
  },
  '.wspx': {
    defaultModule: MODULES.WSO,
    modules      : [MODULES.WSO],
  },
  '.wstx': {
    defaultModule: MODULES.WSO,
    modules      : [MODULES.WSO],
  },
  '.mdbx': {
    defaultModule: MODULES.REPORTING_TOOL,
    modules      : [MODULES.REPORTING_TOOL],
  },
};
