import { useMemo } from 'react';
import { Alert, AlertActions, AlertContent, AlertIcon, Button, Stack } from '@mtb/ui';
import { STORAGE_PROVIDER_KEYS } from '../../../constants.js';
import { useTranslation } from '../../../services/i18n.js';
import { useCloudExplorer } from '../hooks/index.ts';

export const CloudExplorerGooglePickerAlert = () => {
  const [t] = useTranslation();
  const { provider /* , refresh */ } = useCloudExplorer();
  const showPicker = useMemo(
    () => provider === STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE,
    [provider],
  );

  // TODO FIX THIS
  // const handleOnClick = useCallback(async () => {
  //   const didPickItems = await DialogClient.googlePicker();
  //   if (didPickItems) {
  //     await refresh();
  //   }
  // }, [refresh]);
  // TODO FIX THIS

  if (!showPicker) {
    return null;
  }

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture
    <Alert
      severity="info"
      sx={{ width: '100%', flexShrink: 0 }}>
      <AlertIcon />
      <Stack
        direction="row"
        gap={2}
        sx={{ width: '100%' }}>
        {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture */}
        <AlertContent>
          {t('picker.whitelistFiles')}
        </AlertContent>
        {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture */}
        <AlertActions>
          {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture */}
          <Button
            color="secondary"
            onClick={/* handleOnClick */() => console.log('fix me')}>{t('picker.grantAccess')}</Button>
        </AlertActions>
      </Stack>
    </Alert>
  );
};
