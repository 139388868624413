import type { StorageProviderKey } from '@';
import {
  Collapse,
  FolderOpenedOutlinedIcon,
  FolderOutlinedIcon,
  FolderSharedOutlinedIcon,
  List,
  Stack,
} from '@mtb/ui';
import { CLOUD_STORAGE_CATEGORIES } from '../../../../constants';
import { useProvider } from '../../../../hooks';
import { useTranslation } from '../../../../services/i18n';
import { ProviderCategoryButton } from '../ProviderCategoryButton';
import { ProviderHeader } from '../ProviderHeader';

type ProviderListProps = {
  provider: StorageProviderKey;
  providerName: string;
  providerIcon: React.ReactNode;
};
export const ProviderList = ({ provider, providerName, providerIcon }: ProviderListProps) => {
  const [t] = useTranslation();
  const { isSignedIn } = useProvider(provider);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture
    <List>
      <ProviderHeader
        provider={provider}
        title={providerName}>
        {providerIcon}
      </ProviderHeader>
      {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture */}
      <Collapse
        data-testid="provider-list-collapse"
        in={isSignedIn}>
        <Stack
          pl={2.5}
          pt={1}>
          <ProviderCategoryButton
            category={CLOUD_STORAGE_CATEGORIES.ALL}
            provider={provider}
            title={t('connection.all')}>
            {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore - Missing onPointerEnterCapture, onPointerLeaveCapture */}
            <FolderOutlinedIcon />
          </ProviderCategoryButton>
          <ProviderCategoryButton
            category={CLOUD_STORAGE_CATEGORIES.RECENT}
            provider={provider}
            title={t('connection.recent')}>
            {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore - Missing onPointerEnterCapture, onPointerLeaveCapture */}
            <FolderOpenedOutlinedIcon />
          </ProviderCategoryButton>
          <ProviderCategoryButton
            category={CLOUD_STORAGE_CATEGORIES.SHARED}
            provider={provider}
            title={t('connection.shared')}>
            {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore - Missing onPointerEnterCapture, onPointerLeaveCapture */}
            <FolderSharedOutlinedIcon />
          </ProviderCategoryButton>
        </Stack>
      </Collapse>
    </List>
  );
};
