import {
  GoogleDriveIcon,
  MicrosoftOneDriveIcon,
  Stack,
  Typography,
} from '@mtb/ui';
import { STORAGE_PROVIDER_KEYS } from '../../../constants';
import { useTranslation } from '../../../services/i18n';
import { OpenLocal } from './OpenLocalFile';
import { ProviderList } from './ProviderList';

export const CloudExplorerProviderList = () => {
  const [t] = useTranslation();

  return (
    <Stack
      sx={{ minWidth: 200, maxWidth: 250, width: '20%', flex: '1 0 auto' }}>
      <Typography
        color="text.secondary"
        noWrap
        sx={{ pt: 3, pb: 1 }}
        variant="title-md"
        weight="semibold">
        {t('connection.repository')}
      </Typography>
      <ProviderList
        provider={STORAGE_PROVIDER_KEYS.ONE_DRIVE}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - Missing onPointerEnterCapture, onPointerLeaveCapture
        providerIcon={<MicrosoftOneDriveIcon />}
        providerName={t('connection.oneDrive')} />
      <ProviderList
        provider={STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - Missing onPointerEnterCapture, onPointerLeaveCapture
        providerIcon={<GoogleDriveIcon />}
        providerName={t('connection.googleDrive')} />
      <OpenLocal />
    </Stack>
  );
};
