/**
 * The IDs of app rail drawers.
 */
export const PLATFORM_APPRAIL_DRAWER_IDS = {
  STORAGE_EXPLORER     : 'storage-explorer' as const,
  NAVIGATOR            : 'navigator' as const,
  WSO_NAVIGATOR        : 'wso-navigator' as const,
  WSO_TEMPLATES        : 'wso-templates' as const,
  WSO_SHAPES           : 'wso-shapes' as const,
  REPORTING_TOOL_ASSETS: 'reportingtool-assets' as const,
  REPORTING_TOOL_DATA  : 'reportingtool-data' as const,
  REPORTING_TOOL_PAGES : 'reportingtool-pages' as const,
};
