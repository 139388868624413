import type { MutableRefObject, PropsWithChildren } from 'react';
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Input, useEventCallback, useForkRef, useTheme } from '@mtb/ui';
import {
  FileSizeTooLargeDialog,
  InvalidFileTypeDialog,
} from '../../../../../dialogs';
import { ON_OPEN_REASONS } from '../../../constants';
import { useCloudExplorer } from '../../../hooks';
import { ERRORS, isFileImage, validateFile } from './utils';

const MAX_FILE_SIZE = Infinity;

type FileUploaderProps = PropsWithChildren<{
  children: React.ReactElement;
  actions?: MutableRefObject<Record<string, unknown>>;
  [other: string]: unknown; // To allow other props
}>;

export const FileUploader = forwardRef<HTMLInputElement, FileUploaderProps>((
  { children, actions, ...other }, ref,
) => {
  const theme = useTheme();
  const inputRef = useRef();
  const handleRef = useForkRef(inputRef, ref);
  const [error, setError] = useState<string | null>(null);
  const { defaultFilter, onOpen, onError } = useCloudExplorer();

  const handleOnDialogClose = useEventCallback(() => setError(null));
  const handleOnChange = useCallback(
    // This event sucks to type. This type is close, but feels wrong. ChangeEvent<HTMLInputElement> & { dataTransfer?: { files?: File[] } }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (event: any) => {
      const updatedFiles = [];
      const selectedFiles = event.dataTransfer
        ? event.dataTransfer.files
        : event.target.files;
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];

        const { isValid, error } = validateFile(file, {
          accept : defaultFilter,
          maxSize: MAX_FILE_SIZE,
        });

        if (!isValid) {
          setError(error);
          continue;
        }

        if (isFileImage(file)) {
          file.objectURL = window.URL.createObjectURL(file);
        }

        updatedFiles.push(file);
      }

      if (updatedFiles.length) {
        try {
          await onOpen?.(updatedFiles[0], ON_OPEN_REASONS.FILE_UPLOAD);
        } catch (error) {
          console.error(error);
          onError?.(error);
        }
      }
    },
    [defaultFilter, onOpen, onError],
  );

  const handleOnClick = useEventCallback(({ onClick } = {}) => (event: Event) => {
    if (onClick) {
      handleOnClick(event);
    }

    if (inputRef.current) {
      // @ts-expect-error-next-line - useForkRef does some magic that typescript doesn't understand
      inputRef.current.click();
    }
  });

  useImperativeHandle(
    actions,
    () => ({
      open: handleOnClick(),
    }),
    [handleOnClick],
  );

  return (
    <>
      <Input
        aria-hidden
        inputProps={{
          tabIndex     : -1,
          'aria-hidden': true,
          // @ts-expect-error-next-line - 'data-testid' is a custom attribute passed through '...other'
          'data-testid': 'file-uploader-input',
          accept       : defaultFilter,
        }}
        inputRef={handleRef}
        // @ts-expect-error-next-line - either style is wrong or visually hidden has a wrong return type
        style={theme.mixins.visuallyHidden()}
        type="file"
        onChange={handleOnChange} />
      {React.cloneElement(children, {
        'data-testid': 'file-uploader-button',
        ...children.props,
        ...other,
        onClick      : handleOnClick(children.props),
      })}
      <InvalidFileTypeDialog
        open={error === ERRORS.INVALID_FILE_TYPE}
        onClose={handleOnDialogClose} />
      <FileSizeTooLargeDialog
        open={error === ERRORS.FILE_TOO_LARGE}
        onClose={handleOnDialogClose} />
    </>
  );
},
);

export default FileUploader;
