/**
 * The loading state types for a plane.
 */
export const PLANE_STATUS_TYPES = {
  PENDING: 'pending',
  LOADING: 'loading',
  IDLE   : 'idle',
  ERROR  : 'error',
} as const;

export const PLANE_HEALTH_TYPES = {
  // The planes remote module and cloud storage project are unknown, we haven't checked yet.
  UNKNOWN     : 'unknown',
  // The planes remote module and cloud storage project are healthy.
  HEALTHY     : 'healthy',
  // The planes remote module is not healthy, but the cloud storage project exists and is recoverable. (Storage has it loaded.)
  DISCONNECTED: 'disconnected',
  // The planes remote module and cloud storage project are unhealthy but we have the project connection info. (Storage doesnt have it but we now how to load it.)
  RECOVERABLE : 'recoverable',
  // The planes remote module cloud storage project are gone.
  UNHEALTHY   : 'unhealthy',
} as const;
