import type { AsyncPlaneThunkState, Plane } from '../types';
import { miniSerializeError } from '@reduxjs/toolkit';
import CloudStorageClient from '../../../../../clients/cloud-storage';
import { createAsyncThunk } from '../../../redux-helpers/create-async-thunk';
import { PLANE_HEALTH_TYPES } from '../constants';

// This action expects the plane to have an up to date health status.
// Ensure you call checkPlaneHealth before calling this action.
// The two scenarios we'll attempt this on are:
// 1. The plane is disconnected and we can reconnect it, no Cloud Storage needed.
// 2. The plane is recoverable and we can restore it from cloud storage using Cloud Storage.
export const restorePlane = createAsyncThunk.withTypes<AsyncPlaneThunkState>()(
  'planes/restorePlane',
  async (planeId: string, { getState, rejectWithValue, fulfillWithValue }) => {
    const storePlane = getState().planes[planeId];
    // Attempt to recover the plane using Cloud Storage.
    if (storePlane.health === PLANE_HEALTH_TYPES.RECOVERABLE) {
      try {
        const cloudStorageProject = await CloudStorageClient.recoverProject(storePlane.cloudStorageId);
        if (!cloudStorageProject) {
          const error = miniSerializeError(new Error('Failed to recover project'));
          return rejectWithValue(error);
        }

        return fulfillWithValue({
          health        : PLANE_HEALTH_TYPES.HEALTHY,
          cloudStorageId: cloudStorageProject.projectId,
        });
      } catch (error) {
        return rejectWithValue(miniSerializeError(error));
      }
    }

    return fulfillWithValue({
      health        : PLANE_HEALTH_TYPES.HEALTHY,
      cloudStorageId: storePlane.cloudStorageId,
    });
  },
  {
    condition: (planeId: string, { getState }) => {
      const storePlane = getState().planes[planeId] as Plane;
      if (!storePlane) {
        return false;
      }
      // If the plane is unhealthy or healthy, we don't need to attempt to restore it since it's already in the correct state.
      if (storePlane.health === PLANE_HEALTH_TYPES.UNHEALTHY || storePlane.health === PLANE_HEALTH_TYPES.HEALTHY) {
        return false;
      }
    },
  },
);
