import React, {
  cloneElement,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { Menu } from '@mtb/ui';
import { ContextMenuContext } from './context';
import { useContextMenu } from './hooks';

/**
 * `ContextMenuContent` component.
 *
 * @param {Object} props - Component props.
 * @param {import('react').ReactElement} props.children - The children to be rendered.
 *
 * @returns {import('react').ReactElement} The rendered `ContextMenuContent` component.
 */
export const ContextMenuContent = ({ children }) => {
  const { onContextMenu } = useContextMenu();

  return cloneElement(children, {
    ...(children?.props || {}),
    onContextMenu,
    'data-testid': 'context-menu-content',
  });
};

/**
 * `ContextMenuList` component.
 *
 * @param {Object} props - Component props.
 * @param {import('react').ReactElement} props.children - The children to be rendered.
 *
 * @returns {import('react').ReactElement} The rendered `ContextMenuList` component.
 */
export const ContextMenuList = ({ children }) => {
  const { position, anchorEl, onClose } = useContextMenu();
  const isOpen = useMemo(() => Boolean(position || anchorEl), [position, anchorEl]);
  const childrenArray = useMemo(() => React.Children.toArray(children), [children]);

  const handleOnClick = useCallback(
    (props) => (event) => {
      onClose();
      if (props.onClick) {
        props.onClick(event);
      }
    },
    [onClose],
  );

  return (
    <Menu
      {...(position && {
        anchorReference: 'anchorPosition',
        anchorPosition : { top: position.mouseY, left: position.mouseX },
      })}
      {...(anchorEl && {
        anchorReference: 'anchorEl',
        anchorEl,
        anchorOrigin   : {
          vertical  : 'bottom',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical  : 'top',
          horizontal: 'right',
        },
      })}
      data-testid="context-menu"
      open={isOpen}
      SurfaceProps={{ elevation: 8 }}
      onClose={onClose}>
      {isOpen && childrenArray.map((child) =>
        cloneElement(child, {
          onClick      : handleOnClick(child.props),
          'data-testid': 'context-menu-item',
        }),
      )}
    </Menu>
  );
};

/**
 * `ContextMenu` component.
 *
 * @param {Object} props - Component props.
 * @param {import('react').ReactElement} props.children - The children to be rendered.
 * @param {import('react').Ref<Object>} props.actions - A ref to be used for exposing context menu actions.
 * @param {Function} [props.onOpen] - A function to be called when the context menu is opened.
 *
 * @returns {import('react').ReactElement} The rendered `ContextMenu` component.
 */
export const ContextMenu = ({ actions, children, onOpen }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [position, setPosition] = useState(null);

  const handleOnClose = () => {
    setPosition(null);
    setAnchorEl(null);
  };

  const handleOnContextMenu = useCallback(
    (event) => {
      event.preventDefault();
      switch (event.type) {
        case 'click':
        case 'keydown':
          setAnchorEl(event.currentTarget);
          onOpen?.(event);
          break;
        case 'contextmenu':
        default:
          // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          setPosition(
            !position
              ? {
                mouseX: event.clientX + 2,
                mouseY: event.clientY - 6,
              }
              : null,
          );
          onOpen?.(event);
          break;
      }
    },
    [onOpen, position],
  );

  useImperativeHandle(actions, () => ({
    openContextMenu : handleOnContextMenu,
    closeContextMenu: handleOnClose,
  }));

  return (
    <ContextMenuContext.Provider
      value={{
        onClose      : handleOnClose,
        onContextMenu: handleOnContextMenu,
        position,
        anchorEl,
      }}>
      {children}
    </ContextMenuContext.Provider>
  );
};
