import {
  FileSearch,
  FileUploadButton,
  SaveToButton,
  SignOutButton,
  StorageProviderLogo,
  useStorageExplorer,
  useStorageExplorerItem,
  useStorageExplorerItems,
} from './components';
import {
  // ON_OPEN_REASONS,
  CloudExplorer,
  CloudExplorerProviderList,
  CloudExplorerProviderSettings,
} from './components/CloudExplorer/index.ts';
import {
  ON_OPEN_REASONS,
  StorageExplorer,
  StorageExplorerBreadcrumbs,
  StorageExplorerProviderList,
  StorageExplorerProviderSettings,
  StorageExplorerTable,
  StorageExplorerTableContainer,
  StorageExplorerToolbar,
} from './components/StorageExplorer';
import {
  StorageExplorerBackButton as StorageExplorerBackButtonV1,
  StorageExplorerBreadcrumbs as StorageExplorerBreadcrumbsV1,
  StorageExplorerFooter as StorageExplorerFooterV1,
  StorageExplorerHeader as StorageExplorerHeaderV1,
  StorageExplorerTableBody as StorageExplorerTableBodyV1,
  StorageExplorerTableHead as StorageExplorerTableHeadV1,
  StorageExplorerTable as StorageExplorerTableV1,
  StorageExplorerToolbar as StorageExplorerToolbarV1,
  StorageExplorer as StorageExplorerV1,
} from './components/StorageExplorerV1';
import {
  StorageProjectMenu,
  StorageProjectMenuAutoSave,
  StorageProjectMenuButton,
  StorageProjectMenuContent,
  StorageProjectMenuFileName,
  StorageProjectMenuOpenInDesktop,
  StorageProjectMenuReadonlyInfo,
} from './components/StorageProjectMenu';
import {
  StorageProjectMenuAutoSave as StorageProjectMenuAutoSaveV1,
  StorageProjectMenuButton as StorageProjectMenuButtonV1,
  StorageProjectMenuContent as StorageProjectMenuContentV1,
  StorageProjectMenuName as StorageProjectMenuNameV1,
  StorageProjectMenuOpenInDesktop as StorageProjectMenuOpenInDesktopV1,
  StorageProjectMenuReadonlyInfo as StorageProjectMenuReadonlyInfoV1,
  StorageProjectMenu as StorageProjectMenuV1,
} from './components/StorageProjectMenuV1';
import {
  AUTO_SAVE_STATUS,
  CLOUD_STATUS,
  DEFAULT_FILTER,
  DEFAULT_MAX_SIZE,
  DEFAULT_PROVIDERS,
  PROJECT_STATUS,
  STORAGE_PROVIDERS,
  STORAGE_PROVIDER_KEYS,
} from './constants';
import {
  CLOUD_STORAGE_ERRORS,
  CloudStorageError,
} from './errors/cloud-storage-error.ts';
import {
  useAllCloudStorageItems,
  useCloudStorageProject,
  useCloudStorageStore,
  useNewCloudStorageProject,
  useRecentCloudStorageItems,
  useSharedCloudStorageItems,
} from './hooks';
import { ChangeStorageProviderMenu } from './menus';
import configStore from './services/config.js';
import i18n, {
  changeLanguage,
  changeLocale,
  initI18n,
  useTranslation,
} from './services/i18n';
import ProjectService from './services/projects/index.ts';
import ProviderService from './services/providers/index.ts';
import CloudStorage from './services/store';
import { getNameParts } from './utils';

const useCloudStorage = useCloudStorageStore;

const CloudStorageApi = {
  initialize: (config) => {
    configStore.updateConfig(config);
    ProviderService.validateProviders();
  },
  Provider: ProviderService,
  Project : ProjectService,
};

export {
  AUTO_SAVE_STATUS,
  CLOUD_STATUS,
  CLOUD_STORAGE_ERRORS,
  ChangeStorageProviderMenu,
  CloudExplorer,
  CloudExplorerProviderList,
  CloudExplorerProviderSettings,
  CloudStorage,
  CloudStorageApi,
  CloudStorageError,
  DEFAULT_FILTER,
  DEFAULT_MAX_SIZE,
  DEFAULT_PROVIDERS,
  FileSearch,
  FileUploadButton,
  ON_OPEN_REASONS,
  PROJECT_STATUS,
  STORAGE_PROVIDERS,
  STORAGE_PROVIDER_KEYS,
  SaveToButton,
  SignOutButton,
  StorageExplorer,
  StorageExplorerBackButtonV1,
  StorageExplorerBreadcrumbs,
  StorageExplorerBreadcrumbsV1,
  StorageExplorerFooterV1,
  StorageExplorerHeaderV1,
  StorageExplorerProviderList,
  StorageExplorerProviderSettings,
  StorageExplorerTable,
  StorageExplorerTableBodyV1,
  StorageExplorerTableContainer,
  StorageExplorerTableHeadV1,
  StorageExplorerTableV1,
  StorageExplorerToolbar,
  StorageExplorerToolbarV1,
  StorageExplorerV1,
  StorageProjectMenu,
  StorageProjectMenuAutoSave,
  StorageProjectMenuAutoSaveV1,
  StorageProjectMenuButton,
  StorageProjectMenuButtonV1,
  StorageProjectMenuContent,
  StorageProjectMenuContentV1,
  StorageProjectMenuFileName,
  StorageProjectMenuNameV1,
  StorageProjectMenuOpenInDesktop,
  StorageProjectMenuOpenInDesktopV1,
  StorageProjectMenuReadonlyInfo,
  StorageProjectMenuReadonlyInfoV1,
  StorageProjectMenuV1,
  StorageProviderLogo,
  changeLanguage,
  changeLocale,
  getNameParts,
  i18n,
  initI18n,
  useAllCloudStorageItems,
  useCloudStorage,
  useCloudStorageProject,
  useNewCloudStorageProject,
  useRecentCloudStorageItems,
  useSharedCloudStorageItems,
  useStorageExplorer,
  useStorageExplorerItem,
  useStorageExplorerItems,
  useTranslation,
};

