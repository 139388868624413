import type { AsyncPlaneThunkState } from '../types';
import CloudStorageClient from '../../../../../clients/cloud-storage';
import ModuleClient from '../../../../../clients/module';
import { createAsyncThunk } from '../../../redux-helpers/create-async-thunk';
import { PLANE_HEALTH_TYPES } from '../constants';

export const checkPlaneHealth = createAsyncThunk.withTypes<AsyncPlaneThunkState>()(
  'planes/checkPlaneHealth',
  async (planeId: string, { requestId, getState }) => {
    const storePlane = getState().planes[planeId];
    if (!storePlane) {
      return;
    }

    if (requestId !== storePlane.requestId || storePlane.health === PLANE_HEALTH_TYPES.UNHEALTHY) {
      return storePlane.health;
    }

    const [moduleHealth, storageHealth] = await Promise.all([
      ModuleClient.Events.pulse(storePlane)
        .then(() => true)
        .catch(() => false),
      CloudStorageClient.healthCheckProject(storePlane.cloudStorageId),
    ]);

    if (moduleHealth && storageHealth) {
      return PLANE_HEALTH_TYPES.HEALTHY;
    }

    if (storageHealth) {
      return PLANE_HEALTH_TYPES.DISCONNECTED;
    }

    const isRecoverable = await CloudStorageClient.getIsProjectRecoverable(storePlane.cloudStorageId);
    if (isRecoverable) {
      return PLANE_HEALTH_TYPES.RECOVERABLE;
    }

    return PLANE_HEALTH_TYPES.UNHEALTHY;
  },
);
