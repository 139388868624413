import type { ModuleConfigKey } from '../../../../../modules/types';
import type { AsyncPlaneThunkState } from '../types';
import MODULES from '../../../../../modules';
import { createAsyncThunk } from '../../../redux-helpers/create-async-thunk';
import { checkPlaneHealth } from '../check-plane-health';

export const initializePlane = createAsyncThunk.withTypes<AsyncPlaneThunkState>()(
  'planes/initializePlane',
  async (planeId: string, { dispatch, getState }) => {
    const storePlane = getState().planes[planeId];
    MODULES[storePlane.module as ModuleConfigKey]?.preloadRemoteModule?.();
    await dispatch(checkPlaneHealth(storePlane.id));
  },
);
